import React, { useState, useEffect } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ReactiveBase, ReactiveList } from '@appbaseio/reactivesearch';
import {
	Typography,
	Select,
	MenuItem,
	IconButton,
	Snackbar,
	Checkbox,
} from '@mui/material';
import {
	AddCircle,
	CloudDownload,
	PlaylistAddRounded,
	CloseRounded,
} from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import LoadingDots from '../../components/LoadingDots';
import { useAuth0 } from '../../react-auth0-spa';
import Unauthorized from '../Page401';
import { CardContainer, Column, Line } from '../../styles';
import RenderPagination from '../../components/RenderPagination';
import { usePO } from '../../utils/POContext';
import SubmitQueueDialog from './SubmitQueue';
import CardsAllowedDialog from './CardsAllowedDialog';
import { useCardSelector } from '../../hooks/Classificacoes/CardSelector';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import RadialMenu from '../../components/RadialMenu';
import { changePanelState } from '../../redux/slices/panelControl';
import BasicSearchHeader from '../../components/BasicSearchHeader';
import FilterBarComponent from '../../components/FilterBar';
import InsertListModal from './InsertListModal';
import { getClassificationsSpreadsheetFile } from '../../services/classifications';
import { useSnackbar } from 'notistack';
import ReportsPanel from './ReportsPanel';
import { resetFilters } from '../../redux/slices/filterSlice';

const SearchResults = () => {
	const { t } = useTranslation();
	const { selectedTheme, selectedClient, userData, updateCursorMode } = usePO();
	const { token } = useAuth0();
	const { selectedCards, handleSelectCard } = useCardSelector();
	const dispatch = useDispatch();
	const panelControlList = useSelector((state: any) => state.panelControl);
	const filtersRedux = useSelector((state: any) => state.filters);

	const { enqueueSnackbar } = useSnackbar();

	const bearerToken = `Bearer ${token}`;
	const elasticProxyUrl = `${process.env.REACT_APP_API_BASE_URL}/offer`;
	const cardsDataSet = new Set();

	// Reload elasticsearch module
	const [newKey, setNewKey] = useState(0);
	const [resultsPerPage, setResultsPerPage] = useState(20);
	const [numberOfResults, setNumberOfResults] = useState(0);
	const [resultsMessage, setResultMessages] = useState('');
	const [selectedAll, setSelectedAll] = useState(false);
	const [openInsertListModal, setOpenInsertListModal] = useState(false);
	const [requestBody, setRequestBody] = useState({});
	const [loading, setLoading] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);

	useEffect(() => {
		dispatch(changeActiveMenu('classificacoes'));
		dispatch(resetFilters(filtersRedux.classifications.defaultFilters));
	}, [dispatch]);

	const goodCardsList = useSelector(
		(state: any) => state.cardFeedback.goodCardsList
	);
	const notGoodCardsList = useSelector(
		(state: any) => state.cardFeedback.notGoodCardsList
	);
	const getNewKey = () => {
		setNewKey(newKey + 1);
	};

	useEffect(() => {
		getNewKey();
	}, [
		filtersRedux.classifications.defaultFilters.viewTagIDItems,
		filtersRedux.classifications.defaultFilters.viewAllowedItems,
		filtersRedux.classifications.defaultFilters.viewDenouncementItems,
		filtersRedux.classifications.defaultFilters.viewDenouncement,
	]);

	const handleOpenInsertList = () => {
		setOpenInsertListModal(!openInsertListModal);
	};

	const handleChangeResultsPerPage = (event) => {
		setResultsPerPage(event.target.value as number);
		handleSelectCard(null, 'clear');
	};

	const checkPermission = () => {
		if (userData.roles[0] === 'Denunciante') {
			return false;
		} else {
			return true;
		}
	};

	const standardQuery = () => {
		return {
			query: {
				bool: {
					must: [{ match: { ISactive: true } }],
				},
			},
		};
	};

	const viewClientQuery = () => {
		return {
			query: {
				bool: {
					must_not: [
						{ match: { clientsClassifiedAsGood: selectedClient?.id } },
						{ match: { clientsClassifiedAsNotGood: selectedClient?.id } },
					],
					should: [
						{
							bool: {
								must: [
									{ term: { ISactive: true } },
									{ match: { tagID: `*${selectedClient?.id}*` } },
									{ exists: { field: 'tagID' } },
								],
							},
						},
						{
							bool: {
								must: [{ term: { ISactive: true } }],

								must_not: [{ exists: { field: 'tagID' } }],
							},
						},
					],
					minimum_should_match: 1,
				},
			},
			size: 0,
		};
	};

	/**
	 * Essa query traz todas ofertas exceto as que foram classificadas como ilegais ou legais.
	 *
	 * @returns - ofertas que ainda não foram classificadas para o cliente selecionado
	 */
	const defaultQuery = () => {
		return {
			query: {
				bool: {
					must_not: [
						{ match: { clientsClassifiedAsGood: selectedClient?.id } },
						{ match: { clientsClassifiedAsNotGood: selectedClient?.id } },
						{ match: { ISactive: false } },
					],
				},
			},
			size: 0,
		};
	};

	/**
	 * Essa query retorna apenas as ofertas que foram classificadas como legais para
	 * o cliente selecionado
	 *
	 * @returns - Ofertas que foram classificadas como legais pelo cliente selecionado
	 */
	const defaultQueryAsGood = () => {
		return {
			query: {
				bool: {
					must: [
						{ match: { clientsClassifiedAsGood: selectedClient?.id } },
						{ match: { ISactive: true } },
					],
				},
			},
			size: 0,
		};
	};

	const getDefaultQuery = () => {
		if (selectedClient?.id === undefined) {
			return standardQuery();
		}
		if (filtersRedux.classifications.defaultFilters.viewAllowedItems) {
			return defaultQueryAsGood();
		}

		if (filtersRedux.classifications.defaultFilters.viewTagIDItems) {
			return viewClientQuery();
		}
		return defaultQuery();
	};

	const renderResultsPerPage = () => (
		<Select
			labelId="results-per-page"
			disableUnderline
			variant="standard"
			id="results-select"
			value={resultsPerPage}
			onChange={(event) => handleChangeResultsPerPage(event)}
			style={{
				fontSize: 15,
				border:
					selectedTheme.id === 'dark'
						? `1px solid ${selectedTheme.footerLine}`
						: `1px solid #dedede`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			}}
			sx={{
				'.MuiSelect-select': {
					padding: '5px 10px',
					background:
						selectedTheme.id === 'dark'
							? selectedTheme.overlay3dp
							: selectedTheme.foreground,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					'&:hover': {
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					},
				},
				'.MuiSelect-icon': {
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				},
				'& [aria-expanded=true]': {
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				},
			}}
			inputProps={{
				MenuProps: {
					PaperProps: {
						sx: {
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						},
					},
				},
			}}
		>
			<MenuItem value={20}>20</MenuItem>
			<MenuItem value={50}>50</MenuItem>
			<MenuItem value={100}>100</MenuItem>
		</Select>
	);

	const handleChangePanelState = () => {
		if (panelControlList.panelControl === '') {
			dispatch(changePanelState('classificationPanelControl'));
			setOpenMenu(false);
		} else {
			dispatch(changePanelState(''));
		}
	};

	const handleReportsPanelToggle = () => {
		setOpenMenu(!openMenu);
		if (!openMenu) {
			dispatch(changePanelState(''));
		}
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const cardsData = Array.from(cardsDataSet);
		setSelectedAll(!selectedAll);
		if (event.target.checked) {
			handleSelectCard(cardsData, 'select-all');
			return;
		}
		if (!event.target.checked) {
			handleSelectCard(null, 'clear');
		}
	};

	const logicCheckbox = () => {
		if (selectedCards.length === 0 && selectedAll) {
			setSelectedAll(!selectedAll);
			return !selectedAll;
		} else {
			return selectedCards.length === 0 ? false : selectedAll;
		}
	};

	const renderSelectAllCheckBox = () => (
		<Checkbox
			checked={logicCheckbox()}
			data-testid="selectAllCheckBox"
			size="small"
			onChange={handleSelectAll}
			inputProps={{ 'aria-label': 'select all cards' }}
			style={{
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.primaryDark,
			}}
		/>
	);

	const renderHeader = () => (
		<>
			<Line
				style={{
					height: 42,
					justifyContent: 'center',
					margin: '0 10px 0 10px',
					width: openMenu ? '55%' : '80%',
					position: 'absolute',
					top: 10,
				}}
			>
				<Line style={{ gap: 10 }}>
					<Typography
						noWrap
						variant="subtitle2"
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Classificações.Itens por página')}:
					</Typography>
					{renderResultsPerPage()}
				</Line>
				{checkPermission() ? (
					<Line>
						{renderSelectAllCheckBox()}
						<Typography
							noWrap
							variant="subtitle2"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								marginBottom: -2,
							}}
						>
							{t('Classificações.Selecionar todos')}
						</Typography>
					</Line>
				) : null}
				<Line>
					<BasicSearchHeader />
					<Line>
						<IconButton
							size="small"
							style={{
								borderRadius: 4,
								cursor: 'pointer',
							}}
							onClick={handleChangePanelState}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<PlaylistAddRounded
								fontSize="small"
								style={{
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							/>
							<Typography
								noWrap
								variant="caption"
								align="left"
								data-testid="addFilters"
								style={{
									marginLeft: 4,
									marginBottom: -2,
									color:
										selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								}}
							>
								{t('Classificações.Adicionar filtros')}
							</Typography>
						</IconButton>
					</Line>
				</Line>
				<Line style={{ justifyContent: 'center' }}>
					{numberOfResults > 0 ? (
						<Typography
							noWrap
							variant="caption"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								marginBottom: -2,
							}}
						>
							{`${t(
								'Classificações.Resultados'
							)}:  ${numberOfResults?.toLocaleString(userData.i18nID)}`}
						</Typography>
					) : (
						<Typography
							noWrap
							variant="caption"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{resultsMessage}
						</Typography>
					)}
				</Line>
			</Line>
		</>
	);

	// return cards
	const renderResults = (data) => {
		const dataPackage = data;

		// Build a set to handle card data
		dataPackage.forEach((el) => {
			const tempData = { offerID: el.offerID, tags: el.tags };
			if (
				!notGoodCardsList.includes(el.offerID) &&
				!goodCardsList.includes(el.offerID) &&
				!el.clientsClassifiedAsGood?.includes(selectedClient?.id) &&
				!el.author.authorData?.official?.value
			) {
				cardsDataSet.add(tempData);
			}
		});

		return (
			<Line>
				<CardContainer data-testid="cardRenderClassification">
					{dataPackage.map((el, index) => (
						<Card
							dataPackage={dataPackage}
							data={el}
							index={index}
							margin={8}
							key={el.offerID}
						/>
					))}
				</CardContainer>
			</Line>
		);
	};

	const sortOptions = [
		{
			label: `${t('Classificações.Mais recente')}`,
			dataField: 'created',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Mais antigo')}`,
			dataField: 'created',
			sortBy: 'asc',
		},
		{
			label: `${t('Classificações.Mais relevantes')}`,
			dataField: '_score',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Mais vendidos')}`,
			dataField: 'offerData.items_sold.value',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Atualizados recentemente')}`,
			dataField: 'updated',
			sortBy: 'desc',
		},
		{
			label: `${t('Classificações.Preço')} ▴`,
			dataField: 'offerData.price.value',
			sortBy: 'asc',
		},
		{
			label: `${t('Classificações.Preço')} ▾`,
			dataField: 'offerData.price.value',
			sortBy: 'desc',
		},
	];

	const checkMenuItem01Permissions = () => {
		if (
			userData?.roles?.includes('Administrador da ferramenta') ||
			userData?.roles?.includes('Desenvolvedor')
		) {
			return true;
		}
		return false;
	};

	const menuItem1Icon = (
		<AddCircle
			style={{
				fontSize: 24,
				marginTop: -1,
				marginRight: -0.5,
				color: checkMenuItem01Permissions() ? 'white' : selectedTheme.disabled,
			}}
		/>
	);

	const menuItem2Icon = (
		<CloudDownload
			style={{
				fontSize: 22,
				marginTop: -3,
				color: 'white',
			}}
		/>
	);

	const handleDownload = async () => {
		setLoading(true);
		updateCursorMode('wait');
		enqueueSnackbar(
			t(
				'Classificações.O download iniciará automaticamente. Aguarde por favor.'
			),
			{ variant: 'info' }
		);
		const response: any = await getClassificationsSpreadsheetFile(requestBody);
		if (response.success === true) {
			enqueueSnackbar(t(response.message), {
				variant: 'success',
			});
		} else {
			enqueueSnackbar(
				t(
					response?.message ||
						'Erro interno. Tente mais tarde ou contate o suporte'
				),
				{
					variant: 'error',
				}
			);
		}
		setLoading(false);
		updateCursorMode('default');
	};

	const menuData = [
		{
			icon: menuItem1Icon,
			action: handleOpenInsertList,
			tooltip: 'Classificações.Inserir lista de itens para classificação',
		},
		{
			icon: menuItem2Icon,
			action: handleDownload,
			tooltip: 'Classificações.Baixar planilha com os resultados da busca',
		},
	];

	return userData.permissions?.indexOf('read:offer') !== -1 ? (
		<div>
			<RadialMenu menuData={menuData} loading={loading} />
			<ReactiveBase
				key={newKey + selectedClient?.id}
				app="elasticsearch"
				url={elasticProxyUrl}
				transformRequest={(request: any) => {
					setRequestBody(request.body);
					return request;
				}}
				headers={{
					Authorization: bearerToken,
				}}
				theme={{
					typography: {
						fontFamily: 'Raleway, Helvetica, sans-serif',
					},
					colors: {
						primaryColor: selectedTheme.primaryDark,
						titleColor: 'red',
					},
				}}
			>
				{renderHeader()}
				<Line style={{ alignItems: 'flex-start', height: '100%' }}>
					<Column
						style={{
							height: '100%',
							width:
								panelControlList.panelControl === 'classificationPanelControl'
									? 340
									: 0,
							transition: 'width 0.5s',
						}}
					>
						<FilterBarComponent
							titleSearchField="title"
							authorNameField={['author.nickname', 'author.authorName']}
							anatelSearchField="offerData.anatel_number.value"
							observationSearchField="denouncementObservation"
							denouncementIDField="denouncementID"
							tagSearchField="tagID"
							tagSelectorField="tags.tagID.keyword"
							dateRangeSeekField="updated"
							denounceFilterField
							categoriesSearchField="categories.name.keyword"
							checkboxDenouncementsItemsField
							checkboxTagIDItemsField
							checkboxAllowedItemsField
							sourceSearchField={'sourceID.keyword'}
							platformSearchField={'platformINcountryID.keyword'}
							priceSliderField
							followersSearchField="author.authorData.followers.value"
							followers
							itemsSoldSearchField="offerData.items_sold.value"
							itemsSold
							citySearchField={'author.cityName.keyword'}
							countrySearchField={'author.countryName.keyword'}
							stateSearchField={'author.stateName.keyword'}
						/>
					</Column>
					<Column
						style={{
							height: '100%',
							minWidth: openMenu ? '65%' : '70%',
						}}
					>
						<ReactiveList
							key={newKey}
							componentId="ReactiveListResult"
							defaultQuery={getDefaultQuery}
							dataField="*"
							size={resultsPerPage}
							pagination
							renderPagination={({
								pages,
								totalPages,
								currentPage,
								setPage,
							}) => (
								<RenderPagination
									clearSelectionOnPageChange={
										selectedCards.length === resultsPerPage
									}
									pages={pages}
									totalPages={totalPages}
									currentPage={currentPage}
									setPage={setPage}
								/>
							)}
							sortOptions={sortOptions}
							stream={false}
							react={{
								and: [
									'sourceSearch',
									'tagsSelect',
									'tagSearch',
									'citySearch',
									'countrySearch',
									'platformSearch',
									'priceSearch',
									'searchbox',
									'sellerSearch',
									'stateSearch',
									'tagSelector',
									'searchTagSearch',
									'titleSearch',
									'anatelSearch',
									'observationSearch',
									'denouncementIDSearch',
									'denouncements',
									'denouncementsUsers',
									'categoriesSearch',
									'author.authorData.followers.value',
									'offerData.items_sold.value',
									'dateRangeSeek',
									'date',
								],
								or: ['categorySearch'],
							}}
							style={{ margin: 5, width: '100%' }}
							renderResultStats={(stats) => {
								setNumberOfResults(stats.numberOfResults);
								return null;
							}}
							renderNoResults={() => {
								setNumberOfResults(0);
								setResultMessages(
									t('Classificações.Nenhum resultado encontrado')
								);
								return <div style={{ height: '120vh' }} />;
							}}
							render={({ loading, error, data }) => {
								if (loading) {
									setResultMessages('');
									setNumberOfResults(0);
									return (
										<Column
											data-testid="loadingCards"
											style={{
												height: '100vh',
												width: '100%',
												justifyContent: 'center',
												alignContent: 'center',
												marginTop: '-50px',
												marginBottom: 300,
											}}
										>
											<LoadingDots width={120} height={70} key="loading" loop />

											<Typography
												data-testid="loadingClassificationModule"
												variant="subtitle2"
												style={{
													marginTop: '-10px',
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.primary,
												}}
											>
												{t('Classificações.Carregando resultados')}
											</Typography>
										</Column>
									);
								}
								if (error) {
									setResultMessages('');
									setTimeout(() => {
										getNewKey();
									}, 10000);
									return (
										<Column
											style={{
												height: '100vh',
												width: '100%',
												justifyContent: 'center',
												alignContent: 'center',
												marginTop: '-50px',
												marginBottom: 300,
											}}
										>
											<LoadingDots width={150} height={80} key="loading" loop />
											<Typography
												variant="subtitle2"
												style={{
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.primary,
												}}
											>
												{t(
													'Classificações.Houve um erro ao processar a requisição'
												)}
												.
											</Typography>
											<Typography
												variant="subtitle2"
												style={{
													fontWeight: 'bold',
													color:
														selectedTheme.id === 'dark'
															? selectedTheme.textColorHigh
															: selectedTheme.primary,
												}}
											>
												{t(
													'Classificações.Estamos recarregando a busca. Aguarde por favor.'
												)}
											</Typography>
										</Column>
									);
								}
								return renderResults(data);
							}}
						/>
					</Column>
					{numberOfResults > 0 ? (
						<ReportsPanel
							openMenu={openMenu}
							queryData={requestBody}
							numberOfResults={numberOfResults}
							setOpenMenu={handleReportsPanelToggle}
						/>
					) : null}
				</Line>
			</ReactiveBase>
			<Snackbar
				data-testid="numItemsSelected"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={selectedCards.length > 0}
				message={`${t('Classificações.Itens selecionados')}: ${
					selectedCards.length
				}`}
				action={
					<>
						<SubmitQueueDialog setSelectedAll={setSelectedAll} />
						<CardsAllowedDialog setSelectedAll={setSelectedAll} />

						<IconButton
							size="small"
							aria-label="close"
							color="inherit"
							onClick={() => handleSelectCard(null, 'clear')}
							sx={{
								'&:hover': {
									backgroundColor:
										selectedTheme.id === 'dark' && selectedTheme.primaryLight,
								},
							}}
						>
							<CloseRounded fontSize="small" />
						</IconButton>
					</>
				}
			/>
			<InsertListModal
				handleToggleModal={handleOpenInsertList}
				open={openInsertListModal}
			/>
		</div>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default SearchResults;
