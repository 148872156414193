import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Select,
	TextField,
	Checkbox,
	FormControlLabel,
	MenuItem,
	IconButton,
} from '@mui/material';
import { HelpOutlined, Error, CloseRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../utils/api';
import Loading from '../../../components/LoadingDots';
import { useAuth0 } from '../../../react-auth0-spa';
import { usePO } from '../../../utils/POContext';
import { Column, Line } from '../../../styles';
import { TagObjectType } from '../../../interfaces';
import SelectClient from '../../../components/SelectClient';
import SubmitedResumeDialog from '../SubmitedResumeDialog';
import TagSelector from '../../../components/TagSelector';
import { OfferToolTip } from '../../../helpers';
import { useSnackbar } from 'notistack';
import InsertListPopover from './InsetListPopover';
import {
	submitClassifications,
	submitTags,
} from '../../../services/classifications/bulkActions';
import { makeStyles } from '@mui/styles';
import CategorySelector from 'src/components/CategoriesSelector';
import { submitCategories } from 'src/services/classifications/bulkActions';
import { CategoryStructure } from 'src/interfaces/Categories';

interface Props {
	handleToggleModal: () => void;
	open: boolean;
}

const InsertListModal: React.FC<Props> = ({ handleToggleModal, open }) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const {
		classificationTypes,
		selectedClient,
		selectedTheme,
		tagsActiveClient,
	} = usePO();
	const { enqueueSnackbar } = useSnackbar();

	setupRequestToken(api, token);

	const [denounceObs, setDenounceObs] = useState('');
	const [denounceValue, setDenounceValue] = useState('placeholder');
	const [formattedItems, setFormattedItems] = useState<any[]>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [loading, setLoading] = useState(false);
	const [openResumeDialog, setOpenResumeDialog] = useState(false);
	const [submitDataResults, setSubmitDataResults] = useState<any>();
	const [tagsToSubmit, setTagsToSubmit] = useState<any>([]);
	const [selectedCategory, setSelectedCategory] =
		useState<CategoryStructure | null>();
	const [formError, setFormError] = useState<string[]>([]);
	const [checkBoxesState, setCheckboxesState] = React.useState({
		checkedClassify: true,
		checkedTag: true,
	});

	const useStyles = makeStyles(() => ({
		periodicityStyle: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
		menuItem: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.background,
			},
		},
	}));

	const classes = useStyles();

	const handleDenounceReasonChange = (event: any) => {
		setDenounceValue(event.target.value);
		formError.filter((item) => item !== 'denounceValue');
	};

	const handleClose = () => {
		handleToggleModal();
		setDenounceObs('');
		setFormattedItems([]);
		setLastTags([]);
		setTagsToSubmit([]);
		setFormError([]);
		setCheckboxesState({
			checkedClassify: true,
			checkedTag: true,
		});
		setDenounceValue('placeholder');
	};

	// Dialog thar shows the results of the submit
	const handleCloseResumeDialog = () => {
		setOpenResumeDialog(false);
	};

	const handleCategorySelect = (category) => {
		setSelectedCategory(category);
	};

	const handleSaveCategories = async () => {
		if (selectedCategory?.id) {
			const categoriesPayload = {
				offerIDs: formattedItems,
				categoryID: selectedCategory.id,
			};

			try {
				const categoriesResponse = await submitCategories(categoriesPayload);
				if (categoriesResponse?.success) {
					enqueueSnackbar(
						t('Classificações.Categoria adicionada com sucesso'),
						{
							variant: 'success',
						}
					);
				} else {
					enqueueSnackbar('Erro ao processar Categorias', { variant: 'error' });
				}
			} catch (error) {
				enqueueSnackbar('Erro ao salvar categoria', { variant: 'error' });
			}
		}
	};

	const handleSubmitClassifications = async () => {
		if (!formError.includes('denounceValue') || !formError.includes('IDlist')) {
			setLoading(true);
			const goodItems: any = [];
			const notGoodItems: any = [];
			const payload = {
				offerID: formattedItems,
				classificationModel: {
					classificationTypeID: denounceValue,
					denounceStatusID: 'CLASSIFICADA',
					clientID: selectedClient.id,
					observation: denounceObs,
				},
			};
			const response = await submitClassifications(payload);
			if (response?.success) {
				response.data.map((item) => {
					if (item.status < 300) {
						goodItems.push(item);
					}
					if (item.status >= 400) {
						notGoodItems.push(item);
					}
					return null;
				});
				if (notGoodItems.length === 0) {
					enqueueSnackbar(
						t('Classificações.Classificações enviadas com sucesso'),
						{
							variant: 'success',
						}
					);
					setSubmitDataResults({ good: goodItems, notGood: notGoodItems });
					setTimeout(() => {
						setOpenResumeDialog(true);
					}, 500);
				} else {
					enqueueSnackbar(
						t('Classificações.Algumas classificações não puderam ser enviadas'),
						{ variant: 'warning' }
					);
					setSubmitDataResults({ good: goodItems, notGood: notGoodItems });
					setTimeout(() => {
						setOpenResumeDialog(true);
					}, 500);
				}
				setTimeout(() => {
					setLoading(false);
					handleClose();
				}, 1000);
			} else {
				setLoading(false);
				setTagsToSubmit(lastTags);
				enqueueSnackbar(t('Classificações.Erro ao processar classificação'), {
					variant: 'error',
				});
			}
		}
	};

	const handleSubmitTags = async () => {
		if (!formError.includes('tags') || !formError.includes('IDlist')) {
			setLoading(true);
			const tagsPayload = {
				offerIDs: formattedItems,
				tagIDs: checkBoxesState.checkedTag
					? tagsToSubmit.map((el: TagObjectType) => el.tagID)
					: [],
			};
			const response = await submitTags(tagsPayload, selectedClient.id);
			if (response?.success) {
				setLastTags(tagsToSubmit);
				enqueueSnackbar(t(`Classificações.${response.data[0].detail}`), {
					variant: 'success',
				});
			} else {
				setLoading(false);
				setTagsToSubmit(lastTags);
				enqueueSnackbar('Erro ao processar Etiquetas', { variant: 'error' });
			}
			if (checkBoxesState.checkedClassify === false) {
				handleClose();
				setLoading(false);
			}
		}
	};

	const formCheck = () => {
		if (checkBoxesState.checkedClassify && denounceValue === 'placeholder') {
			setFormError((formError) => [...formError, 'denounceValue']);
			enqueueSnackbar(
				t('Classificações.Selecione um motivo de classificação'),
				{
					variant: 'warning',
				}
			);
			return;
		} else {
			formError.filter((item) => item !== 'denounceValue');
		}
		if (formattedItems.length === 0) {
			setFormError((formError) => [...formError, 'IDlist']);
			enqueueSnackbar(
				t('Classificações.Insira uma lista de IDs para processamento'),
				{
					variant: 'warning',
				}
			);
			return;
		} else {
			formError.filter((item) => item !== 'IDlist');
		}
		if (checkBoxesState.checkedTag && tagsToSubmit.length === 0) {
			setFormError((formError) => [...formError, 'tags']);
			enqueueSnackbar(t('Classificações.Selecione ao menos uma etiqueta'), {
				variant: 'warning',
			});
			return;
		}
		if (checkBoxesState.checkedTag) {
			handleSubmitTags();
		}
		if (checkBoxesState.checkedClassify) {
			handleSubmitClassifications();
		}
		if (selectedCategory?.id) {
			handleSaveCategories();
		}

		setFormError([]);
	};

	const handleObservationChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setDenounceObs(event.target.value);
	};

	const handleChangeChecked = (event) => {
		setCheckboxesState({
			...checkBoxesState,
			[event.target.name]: event.target.checked,
		});
	};

	const renderBulkTypeSelector = () => (
		<Line
			style={{ marginTop: 10, marginLeft: 20, justifyContent: 'flex-start' }}
		>
			<FormControlLabel
				control={
					<Checkbox
						checked={checkBoxesState.checkedClassify}
						onChange={handleChangeChecked}
						name="checkedClassify"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				}
				label={
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Classificações.Classificar')}
					</Typography>
				}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={checkBoxesState.checkedTag}
						onChange={handleChangeChecked}
						name="checkedTag"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					/>
				}
				label={
					<Typography
						style={{
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						}}
					>
						{t('Classificações.Etiquetar')}
					</Typography>
				}
			/>
		</Line>
	);

	const renderDenounceReasons = () => {
		if (selectedClient !== undefined && selectedClient !== null) {
			if (denounceValue !== 'PSTLG' && classificationTypes?.length) {
				return (
					<div style={{ marginTop: 10, marginBottom: 5, width: '100%' }}>
						<Select
							variant="outlined"
							fullWidth
							displayEmpty
							error={
								denounceValue === 'placeholder' &&
								formError.includes('denounceValue')
							}
							value={denounceValue}
							onChange={handleDenounceReasonChange}
							margin="dense"
							classes={{
								select: classes.periodicityStyle,
								root: classes.menuItem,
							}}
							sx={{
								height: '40px',
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
								'.MuiSelect-select': {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
								'.MuiSelect-icon': {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								},
								'& [aria-expanded=true]': {
									background:
										selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
								},
							}}
							MenuProps={{
								MenuListProps: {
									disablePadding: true,
								},
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							inputProps={{
								MenuProps: {
									MenuListProps: {
										disablePadding: true,
									},
									PaperProps: {
										sx: {
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay3dp
													: selectedTheme.foreground,
											color:
												selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: '',
										},
									},
								},
							}}
						>
							<MenuItem
								disabled
								id="teste"
								value="placeholder"
								style={{
									backgroundColor:
										selectedTheme.id === 'dark'
											? selectedTheme.overlay6dp
											: selectedTheme.foreground,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorDisable
											: '',
								}}
							>
								{t('Classificações.Motivo da Classificação')}
							</MenuItem>
							{classificationTypes.map((item) => {
								if (item.ISactive && !item.ISgood) {
									return (
										<MenuItem
											value={item.id}
											key={item.name}
											style={{
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.overlay3dp
														: selectedTheme.foreground,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
											}}
										>
											{t('ClassificationType.' + item.id)}
										</MenuItem>
									);
								}
								return null;
							})}
						</Select>
						{denounceValue !== 'placeholder' && denounceValue !== 'PSTLG' ? (
							<Line style={{ minHeight: 30, maxWidth: 500 }}>
								<Typography
									variant="subtitle2"
									style={{
										opacity: 0.8,
										margin: 10,
										marginBottom: -10,
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									}}
								>
									{classificationTypes.map((item) =>
										item.id === denounceValue
											? t('ClassificationTypeDescription.' + item.description)
											: null
									)}
								</Typography>
							</Line>
						) : null}
					</div>
				);
			}
		}
		return (
			<Column style={{ rowGap: 10 }}>
				<Typography
					sx={{
						color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
					}}
				>
					{t('Classificações.Escolha um cliente para classificar o anúncio')}:
				</Typography>
				<SelectClient />
			</Column>
		);
	};

	const renderCategories = () => (
		<Column>
			<Line
				style={{
					justifyContent: 'flex-end',
					alignItems: 'flex-end',
					width: '100%',
					marginTop: 20,
					padding: '0 5px',
				}}
			></Line>
			<CategorySelector
				onCategorySelect={handleCategorySelect}
				selectedCategory={selectedCategory}
			/>
		</Column>
	);

	const renderTags = () => (
		<Column>
			<Line
				style={{
					justifyContent: 'flex-end',
					alignItems: 'flex-end',
					width: '100%',
					marginTop: 20,
					padding: '0 5px',
				}}
			>
				<OfferToolTip
					title={`${t(
						'Classificações.Cada anúncio receberá individualmente estas etiquetas após o envio da lista'
					)}`}
				>
					<HelpOutlined
						style={{
							fontSize: 12,
							marginBottom: '-25px',
							marginRight: '5px',
							zIndex: 10000,
							color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							opacity: 0.8,
						}}
					/>
				</OfferToolTip>
			</Line>
			<TagSelector
				dataArray={tagsToSubmit}
				setDataArray={setTagsToSubmit}
				suggestions={tagsActiveClient}
				error={formError.includes('tags')}
				limitTags={3}
				autoSave={false}
				width={'540px'}
				placeholder={t('Classificações.Etiquetas')}
			/>
		</Column>
	);

	const renderWarning = () => (
		<Line
			style={{
				width: '100%',
				background: selectedTheme.background,
				justifyContent: 'center',
				opacity: 0.7,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '10px 15px',
			}}
		>
			<Error style={{ fontSize: 30, color: 'orange' }} />
			<Typography
				variant="caption"
				sx={{
					width: '70%',
					marginLeft: '20px',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{t(
					`Classificações.Durante o processamento de múltiplos anúncios o sistema ficará em espera. Por favor, aguarde.`
				)}
			</Typography>
		</Line>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				border: `1px solid ${
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2'
				}`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme.foreground,
				padding: 10,
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					margin: '2px 10px 0 10px',
				}}
			>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={60} height={30} loop />
				) : (
					<Button
						size="small"
						onClick={formCheck}
						disabled={
							loading ||
							(checkBoxesState.checkedClassify === false &&
								checkBoxesState.checkedTag === false)
						}
						style={{
							color:
								loading ||
								(checkBoxesState.checkedClassify === false &&
									checkBoxesState.checkedTag === false)
									? '#c4c4c4'
									: selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: `1px solid ${
								selectedTheme.id === 'dark'
									? selectedTheme.footerLine
									: 'transparent'
							}`,
						}}
					>
						{t('Classificações.Aplicar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				display: 'flex',
				height: 60,
				borderBottom: 'solid',
				borderBottomWidth: 1,
				borderBottomColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '12px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
					}}
				>
					{t('Classificações.Operações em lote por lista de IDs')}
				</Typography>
				<IconButton
					onClick={handleClose}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						sx={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primary,
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const renderObservation = () => (
		<TextField
			id="observation"
			name="observation"
			label={t('Classificações.Observações')}
			value={denounceObs}
			onChange={handleObservationChange}
			variant="outlined"
			style={{
				width: '100%',
				marginTop: 25,
			}}
			inputProps={{
				style: {
					color:
						selectedTheme.id === 'dark' ? selectedTheme?.textColorMedium : '',
				},
			}}
			InputLabelProps={{
				style: {
					color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
				},
			}}
			sx={{
				'& .MuiInputBase-root': {
					'& > fieldset': {
						borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
					},
					':hover': {
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
						},
					},
				},
				background: selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
			}}
		/>
	);

	const renderClient = () => (
		<Line style={{ width: '100%', justifyContent: 'flex-end' }}>
			<Typography
				variant="subtitle1"
				style={{
					margin: '15px 5px',
					opacity: 0.9,
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{`${t('Classificações.Cliente')}: ${selectedClient?.name}`}
			</Typography>
		</Line>
	);

	return (
		<div>
			<Dialog
				open={open}
				maxWidth="sm"
				fullWidth
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						handleClose();
					}
				}}
			>
				{renderHeader()}
				<DialogContent
					style={{
						padding: 0,
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					<Column style={{ padding: '20px 30px 2px 30px' }}>
						<InsertListPopover
							formError={formError}
							formattedItems={formattedItems}
							setFormattedItems={setFormattedItems}
						/>
						{renderBulkTypeSelector()}
						{checkBoxesState.checkedClassify ? renderDenounceReasons() : null}
						{renderCategories()}
						{checkBoxesState.checkedTag ? renderTags() : null}
						{checkBoxesState.checkedClassify ? renderObservation() : null}
						{renderClient()}
					</Column>
					{renderWarning()}
				</DialogContent>
				{renderFooter()}
			</Dialog>
			<SubmitedResumeDialog
				open={openResumeDialog}
				close={handleCloseResumeDialog}
				data={submitDataResults}
			/>
		</div>
	);
};

export default InsertListModal;
