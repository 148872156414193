import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../styles';
import ContactUsModal from '../ContactUsModal';
import { useSnackbar } from 'notistack';
import { usePO } from '../../../utils/POContext';
import BackDropLoading from '../../../components/BackdropLoading';
import { makeStyles } from '@mui/styles';
import i18n from '../../../i18n';
import BrFlag from '../../../assets/images/flags/brazil.png';
import UsFlag from '../../../assets/images/flags/usa.png';
import EsFlag from '../../../assets/images/flags/spain.png';
import { useAuth0 } from '../../../react-auth0-spa';
import { getUserINclient } from '../../../services/userINclient';

interface Props {
	openAccessModal: boolean;
	handleClose: () => void;
}

interface FormData {
	referenceCode: string;
}

const SecondAccess: React.FC<Props> = ({ openAccessModal }: Props) => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedTheme } = usePO();
	const { logout } = useAuth0();

	const [openContactUsModal, setOpenContactUsModal] = useState(false);
	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState<FormData>({
		referenceCode: '',
	});
	const [formErrors, setFormErrors] = useState<any>({});

	const logoutWithRedirect = () =>
		logout({
			returnTo: window.location.origin,
		});

	const handleOpenContactUsModal = () => {
		setOpenContactUsModal(true);
	};

	const handleCloseContactUsModal = () => {
		setOpenContactUsModal(false);
	};

	const handleSaveData = async () => {
		setLoading(true);
		try {
			const response: any = await getUserINclient(formData.referenceCode);

			if (response.success) {
				enqueueSnackbar(
					`${t('Inicio.O usuário foi vinculado com sucesso ao cliente associado ao código de referência')} : ${formData.referenceCode}`,
					{ variant: 'success' }
				);

				setTimeout(() => {
					enqueueSnackbar(
						t(
							'Inicio.O sistema será reiniciado em breve para atualizar suas informações.'
						),
						{ variant: 'warning' }
					);

					setTimeout(() => {
						window.location.reload();
					}, 3500);
				}, 2000);
			} else if (response.status === 404) {
				enqueueSnackbar(
					t(
						'Inicio.O seu código não está funcionando corretamente. Por favor, contate um administrador para obter assistência.'
					),
					{ variant: 'error' }
				);
			}
		} catch (err) {
			enqueueSnackbar(
				t(
					'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.'
				),
				{ variant: 'error' }
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmitData = async () => {
		try {
			const schema = Yup.object().shape({
				referenceCode: Yup.string().required(
					t('Inicio.O campo código é obrigatório.')
				),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			setFormErrors({});
			handleSaveData();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	};

	const useStyles = makeStyles(() => ({
		textField: {
			'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
				borderColor: '#505682',
			},
			'& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline':
				{
					borderColor: '#505682',
				},
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#272E67',
			},
		},
	}));

	const classes = useStyles();

	const renderHeader = () => (
		<DialogTitle
			style={{
				height: 60,
				background: '#DCDB00',
				color: '#FFFFFF',
				padding: '20px 15px',
				display: 'flex',
			}}
		>
			<Line
				style={{
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						color: '#272E67',
						fontSize: 18,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{t('Seu acesso está desabilitado')}
				</Typography>
				<Line
					style={{
						width: 'auto',
						height: 30,
						columnGap: 5,
						borderRadius: 4,
						justifyContent: 'center',
						background: 'white',
					}}
				>
					<IconButton
						onClick={() => handleChangeLanguage('pt-br')}
						size="small"
						sx={{ padding: '7px', borderRadius: '4px' }}
					>
						<img alt="landpage-icon" src={BrFlag} style={{ height: '1rem' }} />
					</IconButton>
					<IconButton
						onClick={() => handleChangeLanguage('es-es')}
						size="small"
						sx={{ padding: '7px', borderRadius: '4px' }}
					>
						<img alt="landpage-icon" src={EsFlag} style={{ height: '1rem' }} />
					</IconButton>
					<IconButton
						onClick={() => handleChangeLanguage('en-us')}
						size="small"
						sx={{ padding: '7px', borderRadius: '4px' }}
					>
						<img alt="landpage-icon" src={UsFlag} style={{ height: '1rem' }} />
					</IconButton>
				</Line>
			</Line>
		</DialogTitle>
	);

	const handleChangeLanguage = (language: string) => {
		i18n.changeLanguage(language);
		localStorage.setItem('language', language);
	};

	const renderContent = () => (
		<DialogContent
			style={{
				display: 'flex',
				padding: '20px 50px 25px 50px',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 20,
			}}
		>
			<Typography align="center" style={{ fontSize: 18, fontWeight: 'bold' }}>
				{t(
					'Inicio.Você ainda não tem as permissões necessárias para acessar a plataforma'
				)}
				.
			</Typography>
			<Column style={{ gap: 10, width: '75%' }}>
				<Typography align="center" style={{ fontSize: 14 }}>
					{`${t('Inicio.Digite seu código de cliente abaixo')}:`}
				</Typography>

				<TextField
					size="small"
					placeholder={t('Inicio.Código de cliente')}
					name="referenceCode"
					id="referenceCode"
					sx={{ width: '100%' }}
					variant="outlined"
					autoComplete="off"
					className={classes.textField}
					inputProps={{ maxLength: 5 }}
					value={formData?.referenceCode}
					helperText={formErrors?.referenceCode}
					error={Boolean(formErrors?.referenceCode)}
					onChange={(e) => {
						setFormData({
							...formData,
							referenceCode: e.target.value.toUpperCase(),
						});
					}}
				/>
				<Typography
					align="center"
					style={{
						fontSize: 14,
						textDecoration: 'underline',
						cursor: 'pointer',
					}}
					onClick={() => logoutWithRedirect()}
				>
					{`${t('Inicio.Deseja trocar de conta?')}`}
				</Typography>
			</Column>
		</DialogContent>
	);

	const renderFooter = () => (
		<Line
			style={{
				height: 60,
				padding: 15,
				borderTopColor: '#f2f2f2',
				borderTopWidth: 1,
				borderTopStyle: 'solid',
				justifyContent: 'space-between',
			}}
		>
			<Button
				onClick={handleOpenContactUsModal}
				variant="text"
				size="small"
				style={{ color: selectedTheme.error }}
			>
				{t('Inicio.Não tenho o código')}
			</Button>
			<Button
				data-testid="confirmRefcode"
				type="submit"
				size="small"
				variant="contained"
				onClick={handleSubmitData}
				style={{
					background: '#272E67',
					color: 'white',
				}}
			>
				{t('Inicio.Entrar')}
			</Button>
		</Line>
	);

	return (
		<>
			<Dialog maxWidth="sm" fullWidth open={openAccessModal}>
				{renderHeader()}
				{renderContent()}
				{renderFooter()}
			</Dialog>
			<ContactUsModal
				openModal={openContactUsModal}
				handleCloseModal={handleCloseContactUsModal}
			/>
			{loading ? (
				<BackDropLoading zIndex={3} smallBackdrop width={250} />
			) : null}
		</>
	);
};
export default SecondAccess;
