import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Link, Typography, Zoom } from '@mui/material';
import {
	ChevronLeft,
	ChevronRight,
	WallpaperRounded,
} from '@mui/icons-material/';
import FormDivider from '../../../../components/FormDivider';
import { OfferToolTip } from '../../../../helpers';
import { Line, Column } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import ImageNotFound from '../../../../assets/images/image_not_found.jpg';
import PlatformIcon from '../../../../components/PlatformIcon';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import TagSelector from '../../../../components/TagSelector';
import Gallery from 'react-image-gallery';
import styles from './image-gallery-offer.module.css';
import classNames from 'classnames';
import { TagObjectType } from '../../../../interfaces';
import { useTranslation } from 'react-i18next';
import { submitTags } from '../../../../services/classifications';
import { useSnackbar } from 'notistack';
import CategorySelector from 'src/components/CategoriesSelector';
import { submitCategories } from 'src/services/classifications/bulkActions';
import { CategoryStructure } from 'src/interfaces/Categories';

/* 
  🚨 Maintenance Note 🚨
	Please be cautious when modifying the height calculations on the main return 
  on this component. They are calculated dynamically based on viewport and other
  elements' heights.	Make sure to thoroughly test any changes on different screen sizes. 
*/

interface Props {
	offerData: any;
}

const LeftColumn: React.FC<Props> = ({ offerData }: Props) => {
	const { selectedTheme, tagsActiveClient, selectedClient, userData } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [imageIndex, setImageIndex] = React.useState(0);
	const [openImageModal, setOpenImageModal] = useState(false);
	const [cardTags, setCardTags] = useState<any[]>([]);
	const [lastTags, setLastTags] = useState<TagObjectType[]>([]);
	const [loadingTags, setLoadingTags] = useState(false);
	const [tagFieldError, setTagFieldError] = useState(false);
	const [selectedCategory, setSelectedCategory] =
		useState<CategoryStructure | null>(offerData.categories);

	const getTagsFromOffer = () => {
		if (offerData?.tags) {
			setCardTags(filteredClientTags);
		} else setCardTags([]);
	};

	useEffect(() => {
		if (offerData.tags) {
			getTagsFromOffer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [offerData]);

	useEffect(() => {
		if (selectedCategory) {
			handleSaveCategories();
		}
	}, [selectedCategory]);

	const filteredClientTags = offerData.tags.filter((tag: any) => {
		return tag.clientID === selectedClient.id;
	});

	const handleShowImage = () => {
		setOpenImageModal(true);
	};

	const handleSaveTags = async () => {
		setLoadingTags(true);
		const tagsPayload = {
			offerIDs: [offerData.offerID],
			tagIDs: cardTags.map((el: TagObjectType) => el.tagID),
		};
		const response: any = await submitTags(tagsPayload, selectedClient.id);
		if (response.success) {
			setLastTags(response.successTags);
			setTagFieldError(false);
			enqueueSnackbar(t(response.message), {
				variant: 'success',
			});
		}
		if (response.error) {
			setTagFieldError(true);
			setCardTags(lastTags);
		}
		setLoadingTags(false);
	};

	const handleCategorySelect = (category) => {
		setSelectedCategory(category);
	};

	const handleSaveCategories = async () => {
		if (selectedCategory?.id) {
			const categoriesPayload = {
				offerIDs: [offerData.offerID],
				categoryID: selectedCategory.id,
			};

			try {
				const categoriesResponse = await submitCategories(categoriesPayload);
				if (categoriesResponse?.success) {
					enqueueSnackbar(
						t('Classificações.Categoria adicionada com sucesso'),
						{
							variant: 'success',
						}
					);
				} else {
					enqueueSnackbar('Erro ao processar Categorias', { variant: 'error' });
				}
			} catch (error) {
				enqueueSnackbar('Erro ao salvar categoria', { variant: 'error' });
			}
		}
	};

	const renderPlatformIcon = () => (
		<Line
			style={{
				justifyContent: 'flex-end',
				height: 1,
				position: 'relative',
				top: 15,
				right: -3,
			}}
		>
			<OfferToolTip
				title={
					<Typography variant="subtitle2" align="center">{`${t(
						'Classificações.Abrir anúncio na plataforma de origem'
					)} (${offerData.platformINcountryID.replace(
						'|',
						' - '
					)})`}</Typography>
				}
				aria-label="inativo"
				enterDelay={700}
				enterNextDelay={700}
				arrow
				TransitionComponent={Zoom}
			>
				<Link href={offerData.permalink} target="blank" style={{ zIndex: 200 }}>
					<PlatformIcon
						data={offerData.platformINcountryID}
						dataType="platform"
						size={50}
						cursor="pointer"
					/>
				</Link>
			</OfferToolTip>
		</Line>
	);

	const renderLeftNav = (onClick: any) => {
		return (
			<ChevronLeft
				style={{
					position: 'absolute',
					left: '-8vw',
					top: 'calc(50% - 30px)',
					zIndex: 200,
					cursor: 'pointer',
					fontSize: 50,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.background,
					filter:
						selectedTheme.tone === 'dark'
							? 'drop-shadow(0 1px 1px rgba(255,255,255,0.5))'
							: 'drop-shadow(0 1px 1px rgba(0,0,0,0.5))',
				}}
				onClick={onClick}
			/>
		);
	};

	const renderRightNav = (onClick: any) => {
		return (
			<ChevronRight
				style={{
					position: 'absolute',
					right: '-8vw',
					top: 'calc(50% - 30px)',
					zIndex: 200,
					cursor: 'pointer',
					fontSize: 50,
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.background,
					filter:
						selectedTheme.tone === 'dark'
							? 'drop-shadow(0 1px 1px rgba(255,255,255,0.5))'
							: 'drop-shadow(0 1px 1px rgba(0,0,0,0.5))',
				}}
				onClick={onClick}
			/>
		);
	};

	const renderImageCarousel = () => {
		let imagesArray = [];
		if (offerData.images) {
			if (offerData.images.length > 0) {
				imagesArray = offerData.images.map((el: string) => {
					return {
						thumbnail: el,
						original: el,
					};
				});
			}
			return (
				<Column
					style={{
						justifyContent: 'center',
						height: '70%',
					}}
				>
					<div className={classNames(styles.modalGallery)}>
						<Gallery
							renderLeftNav={renderLeftNav}
							renderRightNav={renderRightNav}
							renderItem={(item) => {
								return (
									<div className="modal-gallery">
										<img
											style={{
												height: '36vh',
												width: '30vw',
												objectFit: 'contain',
											}}
											src={item.original}
											alt=""
										/>
									</div>
								);
							}}
							key="modal"
							items={imagesArray}
							showThumbnails={false}
							onErrorImageURL={ImageNotFound}
							showPlayButton={false}
							showFullscreenButton={false}
							onSlide={(e) => setImageIndex(e)}
							onClick={handleShowImage}
						/>
						<FullSizeImageDialog
							open={openImageModal}
							setOpen={setOpenImageModal}
							imageIndexToShow={imageIndex}
							images={offerData.images}
						/>
					</div>
				</Column>
			);
		}
		return (
			<Column style={{ justifyContent: 'center', height: 215 }}>
				<WallpaperRounded fontSize="large" color="disabled" />
				<Typography
					variant="subtitle2"
					style={{ marginTop: 10, color: '#A0A0A0', fontWeight: 'bold' }}
				>
					{t('Classificações.Imagem não disponível')}
				</Typography>
			</Column>
		);
	};

	const renderOfferDescription = () => (
		<Column
			style={{
				display: 'flex',
				flexDirection: 'column',
				paddingInline: 10,
				justifyContent: 'flex-start',
				height: '40%',
			}}
		>
			<FormDivider
				name={t('Classificações.Descrição')}
				background={
					selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : 'white'
				}
				color={selectedTheme.id === 'dark' && selectedTheme.textColorMedium}
			/>
			<Typography
				variant="caption"
				style={{
					height: '25vh',
					overflowY: 'auto',
					width: '100%',
					color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
				}}
			>
				{offerData.description}
			</Typography>
		</Column>
	);

	const renderCategorySelector = () => (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				rowGap: 5,
				justifyContent: 'center',
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{`${t('Categorias.Categorias')}`}:
			</Typography>

			<CategorySelector
				onCategorySelect={handleCategorySelect}
				selectedCategory={selectedCategory}
			/>
		</Column>
	);

	const renderTagSelector = () => (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				rowGap: 5,
				justifyContent: 'center',
			}}
		>
			<Typography
				variant="subtitle2"
				style={{
					fontWeight: 'bold',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{`${t('Classificações.Etiquetas')}`}:
			</Typography>

			<TagSelector
				error={tagFieldError}
				readOnly={userData.roles.includes('denunciante')}
				setDataArray={setCardTags}
				dataArray={cardTags}
				handleSaveTags={() => handleSaveTags()}
				suggestions={tagsActiveClient}
				loading={loadingTags}
				autoSave
				size="small"
				tagSize="small"
				rows={1}
				maxItems={6}
				limitTags={2}
				width={'100%'}
			/>
		</Column>
	);

	return (
		<>
			<Grid item xs={5} style={{ rowGap: '15px', display: 'grid' }}>
				{/* cardEsqSup */}
				<Card
					style={{
						height:
							'calc(85vh - 100px - 100px - 15px - 60px)' /* 100vh - header - cardEsqInf - padding - footer */,
						padding: '20px 15px',
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
					}}
				>
					{renderPlatformIcon()}
					{renderImageCarousel()}
					{renderOfferDescription()}
				</Card>

				{/* cardEsqInf */}
				<Card
					style={{
						height: '100px',
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
					}}
				>
					<CardContent>{renderCategorySelector()}</CardContent>
				</Card>

				{/* cardEsqInf */}
				<Card
					style={{
						height: '100px',
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
						border:
							selectedTheme.id === 'dark'
								? `1px solid ${selectedTheme.footerLine}`
								: 'none',
					}}
				>
					<CardContent>{renderTagSelector()}</CardContent>
				</Card>
			</Grid>
		</>
	);
};
export default LeftColumn;
