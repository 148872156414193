import React, { useContext, useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import { api, isAxiosError, setupRequestToken } from '../api';
import Unauthorized from '../../pages/Page401/index';
import Loading from '../../components/LoadingOffer';
import OfferThemes from '../../Themes/offerThemes.json';
import { useAuth0 } from '../../react-auth0-spa';
import { Column } from '../../styles';
import LoadingDots from '../../components/LoadingDots';
import { TagObjectType, TagType } from '../../interfaces';
import { ClientType } from '../../interfaces/Clients';

// TODO Dismember Context for faster App loading
interface ContextProps {
	userData: any;
	languages: any;
	classificationTypes: any;
	updateUserData: () => void;
	updateUserDataClient: () => void;
	statusCode: number;
	setStatusCode: (status: number) => void;
	selectedClient: any;
	setSelectedClient: (client: any) => void;
	selectedTheme: any;
	setSelectedTheme: (theme: any) => void;
	cursorMode: any;
	updateCursorMode: (mode: any) => void;
	userActiveClients: any;
	tagsActiveClient: any;
	searchTagsActiveClient: any;
	tagTypes: TagType[];
	loadingContext: boolean;
	localEnvironment: string;
}

type Environment = 'dev' | 'prod' | '';

export const POContext = React.createContext({} as ContextProps);
export const usePO = () => useContext(POContext);

export const POProvider = ({ accessToken, children }) => {
	const { isAuthenticated } = useAuth0();
	if (accessToken !== '') {
		setupRequestToken(api, accessToken);
	}

	const [loadingContext, setLoadingContext] = useState(true);
	const [localEnvironment, setLocalEnvironment] = useState<Environment>('');
	const [unauthorized, setUnauthorized] = useState(false);
	const [userData, setUserData] = useState<any>();
	const [languages, setLanguages] = useState<any>();
	const [selectedTheme, setSelectedTheme] = useState<any>(OfferThemes[0]);
	const [classificationTypes, setClassificationTypes] = useState<any>();
	const [statusCode, setStatusCode] = useState(0);
	const [selectedClient, setSelectedClient] = useState<ClientType | null>();
	const [cursorMode, setCursorMode] = useState<string | null>();
	const [clientsData, setClientsData] = useState<any[]>([]);
	const [tagsActiveClient, setTagsActiveClient] = useState<
		TagObjectType[] | null
	>([]);
	const [tagTypes, setTagTypes] = useState<TagType[]>([]);

	const [searchTagsActiveClient, setSearchTagsActiveClient] = useState<
		TagObjectType[] | null
	>([]);

	const getSelectedTheme = (selectedTheme) => {
		if (selectedTheme === undefined) {
			setSelectedTheme(OfferThemes[0]);
		} else {
			OfferThemes?.map((el: any) => {
				if (el.id === selectedTheme.id) {
					setSelectedTheme(el);
				}
			});
		}
	};

	const reFetchDataClient = async () => {
		if (isAuthenticated) {
			try {
				const userDataResponse = await api.get('/users/manage-users/validate');
				setUserData(userDataResponse.data);
				getSelectedTheme(userDataResponse.data.preferences.selectedTheme);
				setSelectedClient(userDataResponse.data.preferences.selectedClient);
				if (userDataResponse.data.preferences) {
					try {
						const clientsResponse = await api.get(
							`/userINclient/users/${userDataResponse.data.id}`
						);
						setClientsData(clientsResponse.data);
						if (clientsResponse.data.length === 1) {
							setTimeout(() => {
								setSelectedClient(clientsResponse.data[0]);
							}, 100);
						}
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter dados de clientes ativos.');
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Erro ao obter dados do usuário.');
			}
		} else if (!isAuthenticated) {
			setLoadingContext(false);
			return <Redirect to="/" />;
		}
		return null;
	};

	const reFetchData = async () => {
		if (isAuthenticated) {
			try {
				const userDataResponse = await api.get('/users/manage-users/validate');
				setUserData(userDataResponse.data);
				getSelectedTheme(userDataResponse.data.preferences.selectedTheme);
				setSelectedClient(userDataResponse.data.preferences.selectedClient);
				if (userDataResponse.data.preferences) {
					try {
						const classificationTypeResponse = await api.get(
							'/classificationType'
						);
						setClassificationTypes(classificationTypeResponse.data);
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter tipos de classificação.');
					}
					try {
						const languagesResponse = await api.get('/i18n');
						setLanguages(languagesResponse.data);
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter dados de internacionalização.');
					}
					try {
						const clientsResponse = await api.get(
							`/userINclient/users/${userDataResponse.data.id}`
						);
						setClientsData(clientsResponse.data);
						if (clientsResponse.data.length === 1) {
							setTimeout(() => {
								setSelectedClient(clientsResponse.data[0]);
							}, 100);
						}
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter dados de clientes ativos.');
					}
					try {
						const clientTagsResponse = await api.get(
							`/tags/client/${userDataResponse.data.preferences.selectedClient.id}/tagType/CLIENTE`
						);
						setTagsActiveClient(clientTagsResponse.data);
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter as Tags do cliente ativo.');
						setTagsActiveClient([]);
					}
					try {
						const tagTypeResponse = await api.get(`/tagType`);
						setTagTypes(tagTypeResponse.data);
					} catch (error) {
						// eslint-disable-next-line no-console
						console.log('Erro ao obter os tipos de Tags.');
						setTagTypes([]);
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('Erro ao obter dados do usuário.');
			}
		} else if (!isAuthenticated) {
			setLoadingContext(false);
			return <Redirect to="/" />;
		}
		return null;
	};

	const updateUserData = () => {
		reFetchData();
	};

	const updateUserDataClient = () => {
		reFetchDataClient();
	};

	const updateCursorMode = (mode: any) => {
		setCursorMode(mode);
	};

	// TODO Simplyfy fetchData and reFetchData
	useEffect(() => {
		const fetchData = async () => {
			if (isAuthenticated) {
				try {
					const userDataResponse = await api.get(
						'/users/manage-users/validate'
					);
					setUserData(userDataResponse.data);
					getSelectedTheme(userDataResponse.data.preferences.selectedTheme);
					setSelectedClient(userDataResponse.data.preferences.selectedClient);
					if (userDataResponse.data.preferences) {
						try {
							const classificationTypeResponse = await api.get(
								'/classificationType'
							);
							setClassificationTypes(classificationTypeResponse.data);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter tipos de classificação.');
						}
						try {
							const languagesResponse = await api.get('/i18n');
							setLanguages(languagesResponse.data);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter dados de internacionalização.');
						}
						try {
							const clientsResponse = await api.get(
								`/userINclient/users/${userDataResponse.data.id}`
							);
							setClientsData(clientsResponse.data);
							if (clientsResponse.data.length === 1) {
								setTimeout(() => {
									setSelectedClient(clientsResponse.data[0]);
								}, 100);
							}
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter dados de clientes ativos.');
						}
						try {
							const clientTagsResponse = await api.get(
								`/tags/client/${userDataResponse.data.preferences.selectedClient.id}`
							);
							if (clientTagsResponse.status < 400) {
								filterTags(clientTagsResponse.data);
							}
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter as Tags do cliente ativo.');
							setTagsActiveClient([]);
						}
						try {
							const tagTypeResponse = await api.get(`/tagType`);
							setTagTypes(tagTypeResponse.data);
						} catch (error) {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter os tipos de Tags.');
							setTagTypes([]);
						}
						setLocalEnvironment(
							process.env.REACT_APP_API_BASE_URL ===
								'https://po-api-dev.offertech.com.br'
								? 'dev'
								: 'prod'
						);
					}
					setLoadingContext(false);
				} catch (error) {
					if (isAxiosError(error) && error.response) {
						if (error.response && error.response.status === 403) {
							setUnauthorized(true);
						} else {
							// eslint-disable-next-line no-console
							console.log('Erro ao obter dados do usuário.');
						}
					}
				}
			} else if (!isAuthenticated) {
				setLoadingContext(false);
				return <Redirect to="/" />;
			}
			return null;
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	const filterTags = (data: any) => {
		const tempTags: any = new Set([]);
		const tempCliente: any = new Set([]);

		data?.map((el: any) => {
			if (el.tagTypeID === 'BUSCA' || el.tagTypeID === 'IDE') {
				el.tagID = el.id;
				tempTags.add(el);
			}
			if (el.tagTypeID === 'CLIENTE') {
				el.tagID = el.id;
				delete el.id;
				tempCliente.add(el);
			}
		});
		setTagsActiveClient(Array.from(tempCliente));
		setSearchTagsActiveClient(Array.from(tempTags));
	};

	useEffect(() => {
		if (isAuthenticated && selectedClient) {
			api
				.get(`/tags/client/${selectedClient.id}`)
				.then((res) => {
					if (res.status < 400) {
						filterTags(res.data);
					}
				})
				.catch((err) => {
					console.log(`err:`, err);
					setTagsActiveClient([]);
				});
		}
		//TODO tidy up later
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	const userActiveClients = clientsData.filter((data) => {
		if (data.ISactive) {
			return data;
		}
		return null;
	});

	return loadingContext ? (
		<>
			{unauthorized ? (
				<Unauthorized />
			) : (
				<Column>
					<div
						className="spinner"
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: ' center',
							alignItems: 'center',
							position: 'fixed',
							right: 0,
							bottom: 0,
							minWidth: '100%',
							minHeight: '100%',
						}}
					>
						<Loading width={900} height={270} />
					</div>
					<div
						style={{
							width: '100%',
							padding: '5px 0px 10px 0px',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: ' center',
							alignItems: 'center',
							position: 'fixed',
							right: 0,
							bottom: -135,
							minWidth: '100%',
							minHeight: '100%',
						}}
					>
						<LoadingDots width={100} height={30} loop />
						<Typography
							variant="caption"
							style={{ marginTop: -5 }}
							data-testid="loadingBegin"
						>
							Carregando
						</Typography>
					</div>
				</Column>
			)}
		</>
	) : (
		<POContext.Provider
			value={{
				userData,
				languages,
				updateUserData,
				updateUserDataClient,
				classificationTypes,
				setStatusCode,
				statusCode,
				selectedClient,
				setSelectedClient,
				selectedTheme,
				setSelectedTheme,
				cursorMode,
				updateCursorMode,
				userActiveClients,
				tagsActiveClient,
				searchTagsActiveClient,
				tagTypes,
				loadingContext,
				localEnvironment,
			}}
		>
			{children}
		</POContext.Provider>
	);
};
